import React, { useEffect, useState } from 'react';
import './Form.css'
import Services from '../services/Services';
import CircularProgress from './CircularProgress';
import Modal from './Modal';


function Form({ token, campaignID }) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [kvkk, setKvkk] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [campaignTitle, setCampaignTitle] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("Başvurunuz başarıyla alınmıştır.");
  const [modalContent, setModalContent] = useState("");

  const [showKVKKModal, setShowKVKKModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const resetForm = () => {
    setName("");
    setSurname("");
    setPhone("");
    setEmail("");
    setKvkk(false);
    setAgreement(false);
    setFollowUp(false);
  };

  const kvkkText = `
    <h1 style="text-align: center; font-size: 24px; font-weight: bold;">AVM LOJİSTİK TİCARET A.Ş.</h1>
    <h2 style="text-align: center; font-size: 20px;">6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU GEREĞİNCE</h2>
    <h2 style="text-align: center; font-size: 20px;">EASY POINT AYDINLATMA METNİ</h2>

    <h3 style="font-size: 18px; font-weight: bold;">A. Veri Sorumlusunun Kimliği ve Tanımlar</h3>
    
    <p style="font-size: 16px;">6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca, veri sorumlusu sıfatıyla hareket eden AVM Lojistik Ticaret Anonim Şirketi (“Şirket” “AVM Lojistik”) tarafından kişisel verilerinizin korunmasına büyük önem verilmektedir. AVM Lojistik, Easy Point markası adı altında, bu sıfatın gerektirdiği yükümlülükleri yerine getirmek için gerekli uyum çalışmalarını gerçekleştirmektedir. İşbu bilgilendirme, İlgili kişilerin, şirketimizce hizmet verilen Easy Point Esnaf Noktalarında Digiturk’ün ürünleri ile ilgilenmesi halinde kendisi ile iletişime geçilebilmesi adına, ilgili hizmet(ler)in verilebilmesi için Esnaf iş yeri tarafından şirketimiz adına kayıt alma işleminin gerçekleştirilmesi hususunda; tüm ilgili gerçek kişileri kapsaması adına, işlenebilecek kişisel verilere, bunların işlenme amaçlarına ve kişisel verilerin aktarıldığı taraflara ilişkin bilgilere dair Kanun kapsamında aydınlatma yükümlülüğünü yerine getirmek üzere yapılmaktadır. </p>

    <p style="font-size: 16px;"> 
    Kişilerin yararlandığı hizmetler ya da talep ve rızaları vb. hususlar kişiden kişiye farklılık gösterebileceğinden, aşağıda yer verilen tüm bilgiler her gerçek kişi için söz konusu olmayacaktır. İşbu Aydınlatma Metni gerekli olduğu durumlarda Şirketimiz tarafından güncellenecek ve güncel hali yayımlanacaktır.  
    </p>

    <h3 style="font-size: 18px; font-weight: bold;">B. Toplanan Kişisel Veriler, Veri Toplamanın Yöntemi ve Hukuki Sebebi</h3>
    <p style="font-size: 16px;">Kişisel veriler, kimliğinizi belirleyen ya da belirlenebilir kılan bilgiler anlamına gelmektedir, Şirketimiz tarafından işlenebilecek kişisel verileriniz aşağıda yer alanlar ile sınırlı olmamakla,  </p>

    <p style="font-size: 16px;">Kimlik Bilgileri : Ad- Soyad,</p>
    <p style="font-size: 16px;">İletişim Bilgileri: Telefon, e-mail adresidir.  </p>
    <p style="font-size: 16px;">Cookie Çerez Bilgileri: İnternet sayfası üzerinden, hizmetin kullanılmasına ilişkin tercihler, alışkanlıklar, davranış bilgileri, çerezler, giriş yapılan hesap bilgileri, cihaz, şebeke, ağ, uygulama, konum bilgileri işlenmektedir.</p>

    <p style="font-size: 16px;">Şirketimiz, 6698 sayılı Kişisel Verilerin Korunması Kanunu çerçevesinde, kişisel verileri tamamen veya kısmen otomatik ya da veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla, sözlü, yazılı veya elektronik şekilde (Easy Point Esnaf App) kanuna uygun olarak toplamakta, Kanunun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde bu metnin C ve D bendinde belirtilen amaçlarla işleyebilmekte ve aktarabilmektedir.</p>

    <h3 style="font-size: 18px; font-weight: bold;">C. Kişisel Verilerin Hangi Amaçla İşleneceği  </h3>
    <p style="font-size: 16px;">Kişisel verileriniz aşağıdaki durumlarda Kanuna uygun olarak işlenebilmektedir. </p>

    <ul style="font-size: 16px;">
        <li>Kayıt oluşturulması ve doğrulanması, </li>
        <li>Hizmetin sunulabilmesi adına hizmet sözleşmesinin oluşturulması, </li>
        <li>Hizmetlerin sunulması, ücretlendirilmesi ve faturalandırılması; hizmetlerle ilgili talep ettiğiniz işlemlerin gerçekleştirilmesi ve takibinin sağlanması,</li>
        <li>Hizmetlerin tanıtım ve pazarlaması, bunlara ilişkin sizinle iletişime geçilmesi, kampanya, indirim, fayda, koşul, ücretlendirme gibi hususlardan haberdar edilmeniz; üyelik, etkinliklerden yararlanma gibi olanaklar sağlanması ve bunların kullanımı için gerekli işlemlerin yerine getirilmesi,</li>
        <li> Gerekli durumlarda bilgilerinizin teyit edilmesi;   </li>
        <li>  Size teklif, hediye ve promosyon sunulması, hedefli tanıtım yapılması; size özel kutlama, temenni iletimi, ödül, çekiliş ve beğendiğiniz, tekrar erişmek isteyebileceğiniz, hatırlatılmasından memnuniyet duyabileceğiniz içeriklerin iletilmesi; ödül, çekiliş, yarışmaya ya da benzer bir etkinliğe katılmanız halinde bu kapsamda verdiğiniz bilgilerin etkinlik için kullanılması,   </li>
        <li> Hizmetlerin geliştirilmesi, denetimi ve analizi ile çalışanların eğitimi gibi dahili amaçlar için kullanılması; hizmetlere olan ilginizin değerlendirilmesi, müşteri/kullanıcı memnuniyetinin araştırılması; sizden gelecek soru, şikâyet ve bildirimlerin cevaplanması,   </li>
        <li> İlgili mevzuat gereği saklanması gereken bilgilerinizin muhafazası; bilgi kayıplarının önlenebilmesi için kopyalanması, yedeklenmesi; bilgilerinizin tutarlılığının kontrolünün sağlanması, bilgilerinizin güvenliği için gerekli tüm teknik ve idari tedbirlerin alınması,   </li>
        <li> Üst yönetime yapılacak raporlama ve analizlerin hazırlanması amacıyla.   </li>
        <li> Kanun ve yönetmelikler ile belirlenmiş gerekliliklerin ifa edilmesi (vergi mevzuatı, sosyal güvenlik mevzuatı, borçlar hukuku mevzuatı, ticaret hukuku mevzuatı, iş sağlığı ve güvenliği hukuku, elektronik iletişim ile ilgili mevzuat vs. ilgili tüm mevzuatlar).   </li>
        <li> e-fatura, e-arşiv ve e-irsaliye ile ilgili yükümlülüklerin yerine getirilmesi.   </li>
        <li> Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde kamu kurum ve kuruluşlarının taleplerinin yerine getirilmesi.   </li>
        <li> Kanunda belirtilmiş yasal yükümlülüklerin yerine getirilmesi.   </li>
    </ul>

    <h3 style="font-size: 18px; font-weight: bold;">D. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği  </h3>
    <p style="font-size: 16px;">İşlenen kişisel verileriniz zorunlu olan bilgiler ile sınırlı olmak kaydı ile yurtiçinde, </p>
    <ul style="font-size: 16px;">
        <li>Abonelik sözleşmesi akdedilmesi, teklif, fırsat, kampanya ve promosyon, hediyeler verilebilmesi için şirketimiz ile arasında İş birliği Protokolü imzalanan iş ortaklarımıza ve tedarikçilerimizle, </li>
        <li>Yazılım, kurumsal kaynak planlaması, raporlama, pazarlama vb. gibi işlevlerin yerine getirilmesi amacıyla tedarikçilerle ve çözüm ortaklarıyla,  </li>
        <li>Gerekli kalite, gizlilik ve standart denetimlerinin yapabilmesi amacıyla denetim firmaları ve bilgi güvenliği firmalarıyla,  </li>
        <li>Şirketimiz adına veri işleyen (IT desteği veren, trafik/müşteri-misafir memnuniyeti ölçümleme, profilleme ve segmentasyon desteği veren, satış ve pazarlama alanında sms, mailing, arşivleme başta olmak üzere kişisel verilerin işlenmesi gereken konularda destek veren) firmalarla,  </li>
        <li>İlgili mevzuat hükümlerine istinaden yükümlülüklerimizin yerine getirilmesi ve ticari faaliyetlerimizin denetimi amacıyla ilgili denetim firmalarıyla, bağımsız denetim firmalarıyla, gümrük firmalarıyla, mali müşavir/muhasebe firmalarıyla, hukuk bürolarıyla,  </li>
        <li>Şirket’in kendi iştirakleri, markaları, hissedarları ve ortaklarına, </li>
        <li>Şirket tarafından kurulan ortak veri tabanının işlerliği açısından ilgili tedarikçilerle</li>
        <li>Yasal gereklilikleri ifa etmek ve/veya resmi mercilerin taleplerini yerine getirmek amacıyla kamu kurum ve kuruluşlarıyla, paylaşılabilir. </li>
    </ul>

    <h3 style="font-size: 18px; font-weight: bold;">E. İlgili Kişinin Hakları  </h3>
    <p style="font-size: 16px;">Kanun kapsamında, kişisel verilerinize ilişkin olarak aşağıdaki haklara sahipsiniz. </p>
    
    <ul style="font-size: 16px;">
        <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme. </li>
        <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme. </li>
        <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme. </li>
        <li>Yurt içinde veya yurt dışında kişisel verileriniz aktarıldığı üçüncü kişileri bilme. </li>
        <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme. </li>
        <li>Kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde, Kanun kapsamında kişisel verilerin silinmesini veya yok edilmesini isteme. </li>
        <li>Talebinizce düzeltilen veya silinen veya yok edilen kişisel verilerinizin, eğer aktarılmışsa, aktarıldığı üçüncü kişilere bildirilmesini isteme. </li>
        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle sizin aleyhinize bir sonucun ortaya çıkmasına itiraz etme. </li>
        <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme. </li>
    </ul>

     <p style="font-size: 16px;">İlgili kişi olarak başvurunuzu, internet sitesinde yer alan, https://www.easypoint.com.tr/6698-sayili-kisisel-verilerin-korunmasi-kanunu-geregince-basvuru-formu/ doldurulduktan sonra veya usulüne uygun bir dilekçe hazırladıktan sonra bir nüshasının bizzat elden veya yazılı olarak iadeli taahhütlü posta aracılığı ile Gazi Umur Paşa Sokak Bimar İş Merkezi No:38 D:13 K:4 Beşiktaş/İstanbul adresine iletilmesi veya şahsen başvuru yapması ile, https://www.easypoint.com.tr/6698-sayili-kisisel-verilerin-korunmasi-kanunu-geregince-basvuru-formu/ İlgili Kişi Başvuru formunun doldurulması ve 5070 Sayılı Elektronik İmza Kanunu kapsamındaki “güvenli elektronik imza” ile imzalandıktan sonra güvenli elektronik imzalı formun info@malllogistics.com e-posta adresimize ya da avmlojistik@hs03.kep.tr kep adresine, kayıtlı elektronik posta ile gönderilebilir. Başvurunuzun geçerli bir başvuru olarak kabul edilebilmesi için  Veri Sorumlusuna Başvuru Usulleri Hakkında Tebliğ uyarınca başvuruda, ilgili kişinin; a) Ad, soyad ve başvuru yazılı ise imza, b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası, c) Tebligata esas yerleşim yeri veya iş yeri adresi, ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks numarası, d) Talep konusu, bilgilerini belirtmesi zorunludur. Aksi halde başvuru geçerli bir başvuru olarak değerlendirilmeyecektir. Başvuru formu doldurmadan yapılacak başvurularda burada sayılan hususların eksiksiz olarak AVM LOJİSTİK TİC.A.Ş’ ye iletilmesi gerekmektedir. Kişisel verisi işlenen ilgili kişilerin adına üçüncü kişilerin başvuru talebinde bulunabilmesi için ilgili kişi tarafından başvuruda bulunacak kişi adına noter kanalıyla düzenlenmiş özel vekâletname bulunmalıdır.  </p>

       <p style="font-size: 16px;">Bu kapsamda yapacağınız başvurular, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Ancak, işlemin ayrıca bir maliyet gerektirmesi hâlinde, tarafınıza yazılı olarak cevap verilecekse, on sayfaya kadar ücret alınmaz. On sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem ücreti alınabilir. Ayrıca, başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde, Şirketimiz tarafından talep edilebilecek ücret kayıt ortamının maliyetini geçemez. Başvurunun, Şirketimizin hatasından kaynaklanması hâlinde alınan ücret tarafınıza iade edilir.</p>
  `

  useEffect(() => {
    setIsLoading(true)
    Services.GetBolParaCampaign(token, campaignID)
      .then((res) => {
        if (res.data.status) {
          gelBolParaCompany(res.data.result.companyID)
          setCampaignTitle(res.data.result.title)
        } else {
          setCampaignTitle("Bir hata ile karşılaşıldı")
        }
      })
      .catch(() => setIsLoading(false));
  }, []);

  const gelBolParaCompany = (companyID) => {
    Services.GetBolParaCompanies(token)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.result, "Companies");
          let companyTitle = res.data.result.find(x => x.companyID === companyID).title
          setCompanyName(companyTitle)
        } else {
        }
        setIsLoading(false)
      })
      .catch((err) => console.log(err));
  }

  const sendApplication = (data) => {
    Services.SaveBolParaCampaignResponse(token, campaignID, data)
      .then((res) => {
        setIsLoading(false);
        setShowModal(true)
        if (res.data.status) {
          resetForm();

          setResultMessage(`Başvuru talebiniz alınmıştır. Başvurunuz için ${companyName} tarafından 24 saat içerisinde aranacaksınız.`)
        } else {
          resetForm();
          setResultMessage("Başvurunuz alınırken bir sorunla karşılaşıldı.")
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSendRequest = () => {
    setIsLoading(true);
    let data = [
      {
        name: name,
        lastname: surname,
        phone: phone,
        email: email
      }
    ];
    sendApplication(data);
  };

  const isFormValid = () => {
    return (
      name.trim() !== '' &&
      surname.trim() !== '' &&
      phone.trim() !== '' &&
      phone.length === 10 &&
      kvkk
    );
  };

  const handlePhoneChange = (event) => {
    const input = event.target.value;
    const onlyNumbers = input.replace(/\D/g, '');
    if (onlyNumbers.length <= 10) {
      setPhone(onlyNumbers);
    }
  };

  const openAgreementModal = (type) => {
    if (type === "kvkk") {
      setShowKVKKModal(true)
      setShowAgreementModal(false)
      setModalContent(kvkkText);
    }
  };

  useEffect(() => {
    if (showKVKKModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showKVKKModal]);
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className='form-container' style={{ overflowY: showKVKKModal ? 'hidden' : 'auto' }}>
          <p style={{ width: '300px', fontWeight: '400', fontSize: '18px', color: '#494D59' }}>
            {campaignTitle} <b>- Kayıt Formu</b>
          </p>
          <p style={{ width: '300px', fontWeight: '400', fontSize: '12px', color: '#494D59' }}>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
          </p>
          <div>
            <input
              placeholder='İsim *'
              className='input'
              type='text'
              id='name'
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div>
            <input
              placeholder='Soyisim *'
              className='input'
              type='text'
              id='surname'
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
            />
          </div>
          <div>
            <input
              placeholder='Telefon *'
              className='input'
              type='tel'
              id='phone'
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div>
            <input
              placeholder='E-mail'
              className='input'
              type='email'
              id='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className='checkBox-container'>
            <input
              type='checkbox'
              id='kvkk'
              checked={kvkk}
              onChange={() => setKvkk(!kvkk)}
            />
            <label htmlFor='kvkk' onClick={() => openAgreementModal("kvkk")}>6698 Sayılı Kişisel Verilerin Korunması Kanunu Gereğince
              Aydınlatma Metnini Okudum. Onaylıyorum. *</label>
          </div>
          <div className='checkBox-container'>
            <input
              type='checkbox'
              id='followUp'
              checked={followUp}
              onChange={() => setFollowUp(!followUp)}
            />
            <label htmlFor='followUp'>
              6563 Sayılı Kanun kapsamında AVM Lojistik Ticaret A.Ş. tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanılarak (SMS, e-posta, telefon, faks, çağrı merkezi v.b.) tarafıma her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyon, kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla iletişime geçilmesine açık rıza veriyorum.”
            </label>
          </div>
          <button onClick={() => handleSendRequest()} disabled={!isFormValid()}>
            <p>Hemen Başvur</p>
          </button>
          <Modal visible={showModal}>
            <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', display: 'flex' }}>
              <span style={{ fontSize: '18px', fontWeight: '600' }}>Başvuru Sonucu</span>
              <p>{resultMessage}</p>
              <button style={{ marginTop: '15px' }} onClick={() => setShowModal(false)}><p>Tamam</p></button>
            </div>
          </Modal>

          <Modal visible={showKVKKModal} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <div style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', display: 'flex', paddingBottom: '25px' }}>
              <div style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: modalContent }} />
              <button style={{ marginTop: '15px', marginBottom: '15px' }} onClick={() => setShowKVKKModal(false)}>
                <p>Tamam</p>
              </button>
            </div>
          </Modal>
        </div>
      )}



      <span style={{ height: '53px', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '11px', fontWeight: '500', color: '#919090' }}>
        Copyright ©Easy Point  2024.
      </span>
    </div>
  );
}

export default Form;
